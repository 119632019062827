const openAIModels = {
    'gpt-3.5-turbo': {
        promptTokenPerDollar: 1 / 0.0005 * 1000,
        completionTokenPerDollar: 1 / 0.00015 * 1000,
        name: 'gpt-3.5-turbo-0125',
        feature: '支持 16k 长度，价格便宜，速度更快',
    },
    'gpt-3.5-turbo-16k': { //legacy, same as gpt-3.5-turbo, only for compatibility, should not let user select
        name: 'gpt-3.5-turbo-16k',
        feature: '支持 16k 长度，价格便宜，速度更快',
        promptTokenPerDollar: 1 / 0.0005 * 1000,
        completionTokenPerDollar: 1 / 0.00015 * 1000,
    },
    'gpt-4': {
        promptTokenPerDollar: 1 / 0.03 * 1000,
        completionTokenPerDollar: 1 / 0.06 * 1000,
        name: 'gpt-4-0613',
        feature: '速度略慢，价格略贵，但更智能',
    },
    'gpt-4-turbo': {
        promptTokenPerDollar: 1 / 0.1 * 1000,
        completionTokenPerDollar: 1 / 0.3 * 1000,
        name: 'gpt-4-turbo-2024-04-09',
        feature: '速度快，支持 128k 输入',
    },
    'gpt-4o': {
        promptTokenPerDollar: 1 / 0.025 * 1000,
        completionTokenPerDollar: 1 / 0.1 * 1000,
        name: 'gpt-4o-2024-08-06',
        feature: '最新模型，速度快，支持 128k 输入',
    },
    'gpt-4o-mini': {
        promptTokenPerDollar: 1 / 0.00015 * 1000,
        completionTokenPerDollar: 1 / 0.0006 * 1000,
        name: 'gpt-4o-mini-2024-07-18',
        feature: '最新模型，速度快，支持 128k 输入',
    },
};

const chatAIModels = {
    'claude-3.5-sonnet': {
        promptTokenPerDollar: 1 / 0.15 * 1000,
        completionTokenPerDollar: 1 / 0.3 * 1000,
        name: 'claude-3-5-sonnet',
        feature: '价格适中，是 Claude 系列目前最强模型',
    },
    'claude-3-opus': {
        promptTokenPerDollar: 1 / 0.25 * 1000,
        completionTokenPerDollar: 1 / 0.85 * 1000,
        name: 'claude-3-opus',
        feature: '速度略慢，价格略贵，但更智能',
    },
    'claude-3-haiku': {
        promptTokenPerDollar: 1 / 0.005 * 1000,
        completionTokenPerDollar: 1 / 0.015 * 1000,
        name: 'claude-3-haiku',
        feature: '速度快，价格低！',
    },
    'gemini-1.5-flash': {
        promptTokenPerDollar: 1 / 0.003 * 1000,
        completionTokenPerDollar: 1 / 0.009 * 1000,
        name: 'gemini-1.5-flash',
        feature: '谷歌最新模型，双子座！1m 输入 8k 输出',
    },
    'gemini-1.5-pro': {
        promptTokenPerDollar: 1 / 0.005 * 1000,
        completionTokenPerDollar: 1 / 0.015 * 1000,
        name: 'gemini-1.5-pro',
        feature: '谷歌最新模型，双子座！2m 输入 8k 输出',
    },
    'gpt-3.5-turbo': {
        promptTokenPerDollar: 1 / 0.005 * 1000,
        completionTokenPerDollar: 1 / 0.015 * 1000,
        name: 'gpt-3.5-turbo',
        feature: '支持 16k 长度，价格便宜，速度更快',
    },
    'gpt-4': {
        promptTokenPerDollar: 1 / 0.4 * 1000,
        completionTokenPerDollar: 1 / 0.7 * 1000,
        name: 'gpt-4',
        feature: '速度略慢，价格略贵，但更智能',
    },
    'o3-mini': {
        promptTokenPerDollar: 1 / 0.04 * 1000,
        completionTokenPerDollar: 1 / 0.2 * 1000,
        name: 'o3-mini',
        feature: '不能串流，价格略贵，会复杂思考',
    },
    'o1': {
        promptTokenPerDollar: 1 / 0.2 * 1000,
        completionTokenPerDollar: 1 / 0.7 * 1000,
        name: 'o1',
        feature: '不能串流，价格高，会复杂思考',
    },
    'gpt-4-turbo': {
        promptTokenPerDollar: 1 / 0.15 * 1000,
        completionTokenPerDollar: 1 / 0.35 * 1000,
        name: 'gpt-4-turbo',
        feature: '速度快，支持 128k 输入',
    },
    'gpt-4o': {
        promptTokenPerDollar: 1 / 0.03 * 1000,
        completionTokenPerDollar: 1 / 0.15 * 1000,
        name: 'gpt-4o',
        feature: '最新模型，速度快，支持 128k 输入',
    },
    'gpt-4o-mini': {
        promptTokenPerDollar: 1 / 0.002 * 1000,
        completionTokenPerDollar: 1 / 0.009 * 1000,
        name: 'gpt-4o-mini',
        feature: '比 3.5t 更快更便宜更聪明，支持 128k 输入',
    },
};

export { openAIModels, chatAIModels };
